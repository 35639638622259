import { FormikProps } from "formik";
import { FC } from "react";
import Button, { ButtonProps } from "../../../components/Button";
import Input from "../../../components/Inputs/Index";
import InputPassword from "../../../components/Inputs/Password";

export interface FormAuthFields {
  email: string;
  password: string;
}

interface LoginFormProps extends Pick<ButtonProps, "loading"> {
  formik: FormikProps<FormAuthFields>;
}

const LoginForm: FC<LoginFormProps> = ({ formik, loading }) => {
  let inputFields = {
    labelClassName: "max-w-[4.8rem]",
    inputClassName: "max-w-none",
    required: true,
  };
  return (
    <form
      onSubmit={formik.handleSubmit}
      className="w-full max-w-[35rem] mx-auto mt-10 border border-white-4 rounded-md px-6 pt-8 pb-16 shadow-lg"
    >
      <h1 className="max-auto text-center mb-10">Login</h1>

      <Input
        id="email"
        value={formik.values.email}
        label="Email"
        placeholder="johndoe@gmail.com"
        name="email"
        onChange={formik.handleChange}
        type="email"
        error={formik.touched.email && formik.errors.email}
        {...inputFields}
      />
      <InputPassword
        id="password"
        value={formik.values.password}
        label="Password"
        placeholder="***********"
        name="password"
        onChange={formik.handleChange}
        error={formik.touched.password && formik.errors.password}
        {...inputFields}
        containerClassName="mt-10"
      />
      <Button
        text="Log in"
        type="submit"
        className="mx-auto mt-10 px-10 shadow-xl hover:shadow"
        loading={loading}
        disabled={loading}
      />
    </form>
  );
};

export default LoginForm;
