import axios from "axios";
import { BaseUrl as baseURL } from "../constants/baseUrl";
import { useAuth } from "./useAuth";

const Admin = axios.create({
  baseURL,
});

export const useAdminRequest = () => {
  const { token } = useAuth();

  console.log({ token });

  let adminInstance = Admin;

  if (!token) return { adminInstance };

  // add headers to axios instance
  adminInstance = axios.create({
    baseURL,
    headers: { "x-auth-token": token },
  });

  return { adminInstance };
};
