import { FormikProps } from "formik";
import { FC, InputHTMLAttributes } from "react";
import Input from "../../components/Inputs/Index";

interface PersonalInformationProps {
  formik: FormikProps<any>;
  date: null | Date;
}

const PersonalInformation: FC<PersonalInformationProps> = ({
  formik,
  date,
}) => {
  const displayError = (field: string) => {
    return (formik.touched[field] && formik.errors[field]) as string;
  };

  return (
    <section className="text-black-2">
      <fieldset disabled={!date} className="disabled:opacity-30">
        <h3 className="text-2xl">Personal Information</h3>

        <p className="mb-3">
          Fields with <span aria-label="required">*</span> are required
        </p>
        <Input
          type="email"
          id="email"
          label="Email"
          name="email"
          onChange={formik.handleChange}
          value={formik.values.email}
          required
          placeholder="johndoe@gmail.com"
          error={displayError("email")}
        />
        <Input
          id="name"
          label="Name"
          name="name"
          onChange={formik.handleChange}
          value={formik.values.name}
          required
          placeholder="John Doe"
          error={displayError("name")}
        />
        <Input
          type="tel"
          id="phoneNumber"
          label="Phone"
          name="phoneNumber"
          onChange={formik.handleChange}
          value={formik.values.phoneNumber}
          required
          placeholder="2505982345"
          error={displayError("phoneNumber")}
        />
        <Input
          type="number"
          id="noOfUser"
          label="Number of People"
          // info="(The total number of people you want to book)"
          name="noOfUser"
          onChange={formik.handleChange}
          value={formik.values.noOfUser}
          required
          placeholder="1000"
          error={displayError("noOfUser")}
        />
        <Input
          type={"textarea"}
          id="description"
          label="Description"
          name="description"
          onChange={formik.handleChange}
          value={formik.values.description}
          // required
          placeholder="State your service, e.g hair cut, makeup, etc..."
          error={displayError("description")}
          inputAttrs={{ rows: "2" } as InputHTMLAttributes<HTMLTextAreaElement>}
        />
      </fieldset>
    </section>
  );
};

export default PersonalInformation;
