import { FC } from "react";

const Header: FC = () => {
  return (
    <header className="mb-10">
      <h1 className="text-lg tracking-wider">
        Select the date and time of your appointment then enter your quick
        profile to get your spot.
      </h1>
    </header>
  );
};

export default Header;
