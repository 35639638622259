import { FC } from "react";

const Head: FC<{ heads: string[] }> = ({ heads }) => {
  // heads.forEach((h) => h.split(" ").toString());

  return (
    <thead className="bg-gray-50">
      <tr>
        {heads.map((head, i) => (
          <th
            key={head}
            className={`border-b border-gray-200 ${
              i === 0
                ? "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                : "px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            }`}
          >
            {head.toUpperCase()}
            <span className="text-[0.7rem] inline-block">
              {head.toLowerCase() === "date" ? "(YYYY-MM-DD)" : ""}
              {head.toLowerCase().includes("time") ? "(HH:MM)" : ""}
            </span>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default Head;
