import { ChangeEvent, FC, useEffect, useState } from "react";
import toast from "react-hot-toast";
import ProtecteRoute from "../components/ProtectRoute";
import { useAdminRequest } from "../hooks/useAdminRequest";
import Search from "../modules/admin/Search";
import {
  FetchData,
  searchBookings,
  SearchBooking,
  getInitialBookings,
} from "../utilities/book";
import Table from "./../components/Table/Index";

export type BookingResult = FetchData<SearchBooking>;

export interface SearchFilter {
  search: string;
  // email: string;
  // name: "";
  // noOfUsers: "";
  // location: "";
  startDate: string;
  // endDate: string;
}

const Admin: FC = () => {
  const { adminInstance } = useAdminRequest();
  const [search, setSearch] = useState<SearchFilter>({
    search: "",
    // email: "",
    // name: "",
    // noOfUsers: "",
    // location: "",
    startDate: "",
    // endDate: "",
  });
  const [bookings, setBookings] = useState<BookingResult>({
    loading: true,
    data: [],
    error: "",
  });

  let controller = new AbortController();

  const handleSearchChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setSearch({
      ...search,
      [e.target.name]: e.target.value,
    });
  };

  const searchBooking = async () => {
    if (!search.search && !search.startDate)
      return toast.error("Please enter a search term or select a date", {
        id: "search",
      });

    try {
      setBookings((prev) => ({ ...prev, loading: true }));
      let data = await searchBookings(
        search.search,
        search.startDate,
        controller.signal
      );
      setBookings((prev) => ({ ...prev, data }));
    } catch (error) {
      setBookings((prev) => ({ ...prev, error: "There was an error" }));
    } finally {
      setBookings((prev) => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    if (!search.startDate) return;

    searchBooking();

    return () => {
      if (!search.startDate) return;
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.startDate]);

  useEffect(() => {
    const getInitialData = async () => {
      try {
        setSearch(() => ({ search: "", startDate: "" }));
        let data = await getInitialBookings(adminInstance);
        console.log(data);
        setBookings((prev) => ({ ...prev, data }));
      } catch (error: any) {
        let message = error.message;
        setBookings((prev) => ({ ...prev, error: message }));
      } finally {
        setBookings((prev) => ({ ...prev, loading: false }));
      }
    };

    getInitialData();

    // get data every 5 mins
    let timerID = setInterval(() => {
      getInitialData();
    }, 5 * 60 * 1000);

    return () => {
      clearInterval(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="px-5 py-8">
      <header className="">
        <Search
          handleSearch={(e) => {
            e.preventDefault();
            searchBooking();
          }}
          handleSearchChange={handleSearchChange}
          search={search}
          loading={bookings.loading}
        />
      </header>
      <section>
        <Table {...bookings} />
      </section>
    </main>
  );
};

export default ProtecteRoute(Admin);
