import { FC } from "react";
import { BookingResult } from "../../routes/admin";
import Loader from "../Loader";
import Body from "./Body";
import Head from "./Head";

interface TableProps extends BookingResult {}

const Table: FC<TableProps> = ({ loading, error, data }) => {
  let heads = [
    // "booking Id",
    // "schedule Id",
    "name",
    "date",
    // "email",
    // "start Time",
    // "end Time",
    "time",
    "phone Number",
    "no Of People",
  ];

  return (
    // <div className="px-4 sm:px-6 lg:px-8">
    <div className="">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto flex items-center justify-between">
          <div className="">
            <h1 className="text-xl font-semibold text-gray-900">Users</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the slots in your schdeule.
            </p>
          </div>
          {data?.length ? (
            <p className="text-xl font-extrabold">
              Total number of users: {data.length}
            </p>
          ) : null}
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              {/* <table className="min-w-full divide-y divide-gray-300"> */}
              <table className="min-w-full">
                <Head heads={heads} />
                {!loading && !error && data && (
                  <Body heads={heads} data={data} />
                )}
              </table>
              {(loading || error || data.length === 0) && (
                <div className="border-0 text-center w-full my-3 font-bold">
                  {loading ? (
                    <Loader />
                  ) : error ? (
                    <div>Error</div>
                  ) : data.length === 0 ? (
                    <p className="">No result found</p>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
