import { InputProps } from "../../components/Inputs/Index";
import { SelectProps } from "../../components/Inputs/Select";
import { SearchProps } from "./Search";

export const fields = (props: SearchProps) => [
  {
    id: "search",
    name: "search",
    label: "Search",
    type: "search",
    placeholder: "Search for name, email...",
    value: props.search.search,
    onChange: props.handleSearchChange as InputProps["onChange"],
    showLabel: false,
  },
  // {
  //   id: "email",
  //   name: "email",
  //   label: "Email",
  //   type: "email",
  //   placeholder: "email",
  //   value: props.search.email,
  //   onChange: props.handleSearchChange as InputProps["onChange"],
  // },
  {
    as: "select",
    id: "startDate",
    name: "startDate",
    label: "Start Date",
    value: props.search.startDate,
    onChange: props.handleSearchChange as SelectProps["onChange"],
  },
  // {
  //   as: "select",
  //   id: "endDate",
  //   name: "endDate",
  //   label: "Start Date",
  //   value: props.search.endDate,
  //   onChange: props.handleSearchChange as SelectProps["onChange"],
  // },
  // {
  //   id: "name",
  //   name: "name",
  //   label: "Name",
  //   type: "text",
  //   placeholder: "name",
  //   value: props.search.name,
  //   onChange: props.handleSearchChange,
  // },
  // {
  //   id: "noOfUsers",
  //   name: "noOfUsers",
  //   label: "Users",
  //   type: "number",
  //   placeholder: "users",
  //   value: props.search.noOfUsers,
  //   onChange: props.handleSearchChange,
  // },
  // {
  //   id: "location",
  //   name: "location",
  //   label: "Location",
  //   type: "text",
  //   placeholder: "location",
  //   value: props.search.location,
  //   onChange: props.handleSearchChange,
  // },
];
