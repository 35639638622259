import { FC } from "react";

const Footer: FC = () => {
  return (
    <footer className="mt-4 pt-4 pb-6 px-6 border-t border-white-4">
      <p className="text-center">
        Copyright © {new Date().getFullYear()}. All Rights Reserved
      </p>
    </footer>
  );
};

export default Footer;
