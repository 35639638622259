import { FC } from "react";
import { BookingResult } from "../../routes/admin";

interface BodyProps {
  heads: string[];
  data: BookingResult["data"];
}

const Body: FC<BodyProps> = ({ heads, data }) => {
  heads = heads.map((head) => head.split(" ").join(""));
  console.log({ data });

  return (
    <tbody className="divide-y divide-gray-200 bg-white">
      {data.map((d, index) => (
        <tr key={index}>
          {heads.map((head, i) => (
            <td
              key={i}
              className={`${
                i === 0
                  ? "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                  : "whitespace-nowrap px-3 py-4 text-sm text-gray-500"
              }`}
            >
              {d[head as keyof typeof d]}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export default Body;
