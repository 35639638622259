import { FC, useState } from "react";
import Input, { InputProps } from "./Index";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const InputPassword: FC<InputProps> = (props) => {
  let { type: _, ...others } = props;
  const [type, setType] = useState("password");

  const toggleType = () => setType(type === "password" ? "text" : "password");

  return (
    <Input
      {...others}
      type={type}
      Icon={
        <button onClick={toggleType} className="text-lg" type="button">
          {type === "password" ? <AiFillEye /> : <AiFillEyeInvisible />}
        </button>
      }
    />
  );
};

export default InputPassword;
