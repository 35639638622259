import { useFormik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

import Calendar from "../modules/book/Calendar";
import Header from "../modules/book/Header";
// import Overview from "../modules/book/Overview";
import PersonalInformation from "../modules/book/PersonalInformation";
import {
  // Booking,
  bookSession,
  // getBookings,
  // getSlots,
  // Slot,
} from "../utilities/book";
import { getAvailableSlots } from "../utilities/slot";

export interface SlotsTime {
  loading: boolean;
  error: string;
  data: string[];
}

const Book = () => {
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [slots, setSlots] = useState<SlotsTime>({
    loading: false,
    error: "",
    data: [],
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [calendar, setCalendar] = useState({
    showTimeSelect: false,
  });

  const fetchSlot = async () => {
    if (!date) return;

    try {
      setSlots((prev) => ({ ...prev, loading: true }));
      let data = await getAvailableSlots(date.toString());
      // let slots = data.map((d) => new Date(d).toLocaleTimeString());
      // console.log(slots);
      setSlots((prev) => ({ ...prev, data }));
      setCalendar((prev) => ({ ...prev, showTimeSelect: true }));
      // formik.setFieldValue("time", slots[0]);
    } catch (error) {
      let message =
        "There was an error loading available time. Please try again later.";
      toast.error(message, { id: "time-error" });
      setSlots((prev) => ({
        ...prev,
        error: message,
      }));
    } finally {
      setSlots((prev) => ({ ...prev, loading: false }));
    }
  };

  const handleDateChange = (date: Date) => {
    setDate(date);
  };

  const formik = useFormik<{
    email: string;
    name: string;
    phoneNumber: string;
    description: string;
    noOfUser?: number;
    time: string;
  }>({
    initialValues: {
      email: "",
      name: "",
      phoneNumber: "",
      description: "",
      noOfUser: 1,
      time: "",
    },
    onSubmit: async (values, actions) => {
      if (!values.time) {
        toast.error("Please select a time");
        return;
      }
      // console.log(values);

      // let timePerSession = 30 * 60 * 1000; // 30 minutes in milliseconds

      // add 1 hour to start time of session
      let startDate = new Date(date);
      startDate.setHours(startDate.getHours() + 1);
      // let startTime = startDate.toISOString();

      // // end time is start time + number of users * time per session
      // let endTime = new Date(
      //   startDate.getTime() + timePerSession * +values.noOfUser!
      // ).toISOString();
      // console.log({ startTime, endTime });

      let newDate = new Date(date);

      // format is YYYY-MM-DD e.g 2021-09-01
      let detailYear = newDate.getFullYear();
      let detailMonth: string | number = newDate.getMonth() + 1;
      if (detailMonth < 10) detailMonth = `0${detailMonth}`;
      let detaildate: string | number = newDate.getDate();
      if (detaildate < 10) detaildate = `0${detaildate}`;
      let detailDate = `${detailYear}-${detailMonth}-${detaildate}`;

      try {
        let details = {
          ...values,
          noOfPeople: +values.noOfUser!,
          date: detailDate,
        };

        delete details.noOfUser;

        // console.log({ details });
        setLoading(true);

        toast.success(await bookSession(details));
        await fetchSlot();
        // setDate(null);
        actions.resetForm();
      } catch (error: any) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    },
  });

  // send a request to get available slot whenever date changes
  useEffect(() => {
    fetchSlot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return (
    <main className="px-4 pt-6">
      <form
        onSubmit={formik.handleSubmit}
        className="bg-white-1 w-full max-w-4xl mx-auto p-4 py-8 rounded-md shadow-1 tracking-wide"
      >
        <Header />
        <div className="book__container">
          <Calendar
            formik={formik}
            date={date}
            handleDateChange={handleDateChange}
            {...slots}
          />
          <PersonalInformation formik={formik} date={date} />
        </div>
        <div>
          {/* {date && <Overview />} */}
          <button
            className="block bg-blue-2 text-white border border-blue-3 rounded px-3 pt-1 pb-2 mt-7 mx-auto font-semibold"
            type="submit"
            disabled={!date || loading}
          >
            {loading ? "Loading..." : "Book Now"}
          </button>
        </div>
      </form>
    </main>
  );
};

export default Book;
