// accept string or number, converts it to Date and extracts the date part
export const getDateFormatOfYYYYMMDD = (date: string | number) => {
  let newDate = new Date(date);
  // return `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}`;

  let detailYear = newDate.getFullYear();
  let detailMonth: string | number = newDate.getMonth() + 1;
  if (detailMonth < 10) detailMonth = `0${detailMonth}`;
  let detaildate: string | number = newDate.getDate();
  if (detaildate < 10) detaildate = `0${detaildate}`;

  return `${detailYear}-${detailMonth}-${detaildate}`;
};
