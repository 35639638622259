import {
  ChangeEvent,
  FC,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
} from "react";

export interface InputProps {
  type?: HTMLInputTypeAttribute | "textarea";
  name: string;
  placeholder?: string;
  info?: string;
  value: string;
  id: string;
  onChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  label: string;
  inputAttrs?: InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>;
  required?: boolean;
  error?: string | boolean;
  inputClassName?: string;
  labelClassName?: string;
  containerClassName?: string;
  showLabel?: boolean;
  Icon?: any;
}

const Input: FC<InputProps> = (props) => {
  if (props.showLabel === undefined) {
    props = { ...props, showLabel: true };
  }

  let options = {
    className: `form__input ${props.inputClassName || ""}`.trim(),
    ...props,
    ...props.inputAttrs,
  };

  if (props.type === "textarea") {
    delete options.type;
  } else if (!props.type) {
    options.type = "text";
  }

  const Icon = props.Icon;

  delete options.inputAttrs;
  delete options.showLabel;
  delete options.containerClassName;
  delete options.inputClassName;
  delete options.labelClassName;
  delete options.Icon;

  return (
    <div
      className={`form__control form__control--flex ${
        props.containerClassName || ""
      }`.trim()}
    >
      <label
        htmlFor={props.id}
        className={`form__label ${props.showLabel ? "" : "hidden"} ${
          props.labelClassName || ""
        }`.trim()}
      >
        {props.label}
        {props.info && <span className="text-xs">{props.info}</span>}
        {props.required && <span aria-label="required">*</span>}
      </label>

      <div className="w-full flex flex-col relative">
        {props.type === "textarea" ? (
          <textarea {...options} />
        ) : (
          <>
            <input {...options} />
            {Icon && (
              <span className="absolute right-2 top-[1.35rem] -translate-y-1/2">
                {Icon}
              </span>
            )}
          </>
        )}
        {props.error && (
          <span className="error block text-xs mt-1 text-[red]">
            {props.error}
          </span>
        )}
      </div>
    </div>
  );
};

export default Input;
