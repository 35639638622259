export const BaseUrl = process.env.REACT_APP_BASE_URL;

export const paths = {
  book_user: "/user/{scheduleId}/book",
  get_bookings: "/booking/all?startDate={startDate}&endDate={endDate}",
  get_all_bookings: "/booking/all",
  search_bookings: "/booking/search",
  get_slots: "/booking/slots",
  get_available_slots: "/booking/?date={yyyy-mm-dd}",
  admin_login: "/admin/login",
  // get_available_slots: "/booking/all?time={yyyy-mm-dd}",
};

export const SCHEDULE_ID = process.env.REACT_APP_SCHEDULE_ID;
