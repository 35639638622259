import { FC, FormEventHandler } from "react";
import Select, { SelectProps } from "../../components/Inputs/Select";
import { SearchFilter } from "../../routes/admin";
import Input, { InputProps } from "./../../components/Inputs/Index";
import { fields } from "./fileds";

// interface SearchProps extends Pick<InputProps, "onChange" | "value"> {
//   handleSearch: FormEventHandler<HTMLFormElement>;
// }

export interface SearchProps {
  search: SearchFilter;
  handleSearchChange: InputProps["onChange"] | SelectProps["onChange"];
  handleSearch: FormEventHandler<HTMLFormElement>;
  loading: boolean;
}

const Search: FC<SearchProps> = (props) => {
  let dates = [
    { value: "", label: "Filter by date" },
    { value: "today", label: "Today" },
    { value: "tomorrow", label: "Tomorrow" },
    { value: "week", label: "This Week" },
    { value: "month", label: "This Month" },
    { value: "year", label: "This Year" },
  ];

  return (
    <form onSubmit={props.handleSearch}>
      <div className="flex items-start space-x-3">
        <div className="grid grid-cols-[repeat(auto-fit,minmax(14rem,1fr))]  flex-1 gap-x-5">
          {fields(props).map((field, i) => {
            let { as, ...rest } = field;

            let { onChange, ...others } = rest;

            if (as === "select")
              return (
                <Select
                  {...others}
                  onChange={onChange as SelectProps["onChange"]}
                  options={dates}
                  labelClassName={`${i === 0 ? "" : "w-full max-w-max"}`}
                  inputClassName={`${i === 0 ? "ml-0" : ""}`}
                  containerClassName={`${i === 0 ? "" : "items-center"}`}
                  inputAttrs={i === 0 ? { style: { marginLeft: 0 } } : {}}
                  showLabel
                  key={i}
                />
              );

            return (
              <Input
                {...others}
                onChange={onChange as InputProps["onChange"]}
                placeholder={`${
                  i === 0
                    ? field.placeholder
                    : `filter base on ${field.placeholder}`
                }`}
                labelClassName={`${i === 0 ? "" : "w-auto"}`}
                inputClassName={`${i === 0 ? "ml-0" : ""}`}
                containerClassName={`${i === 0 ? "" : "items-center"}`}
                inputAttrs={i === 0 ? { style: { marginLeft: 0 } } : {}}
                key={i}
              />
            );
          })}
        </div>
        <button
          type="submit"
          className="bg-green-1 text-white rounded-md px-5 pt-[0.35rem] pb-[0.5rem] mb-6"
          disabled={props.loading}
        >
          Search
        </button>
      </div>
    </form>
  );
};

export default Search;
