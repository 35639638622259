import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

type withAuthenticatedUser = (Component: FC) => FC;

const ProtecteRoute: withAuthenticatedUser = (Component) => {
  const Authenticated: FC = (): JSX.Element | null => {
    const { authenticating, token } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
      if (authenticating) return;

      if (!token) navigate("/admin/login");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authenticating, token]);

    if (authenticating) return <div>Authenticating.....</div>;

    return token ? <Component /> : null;
  };

  return Authenticated;
};

export default ProtecteRoute;
