import {
  ButtonHTMLAttributes,
  FC,
  HTMLAttributes,
  MouseEventHandler,
} from "react";
import Loader from "./Loader";

export interface ButtonProps {
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  text: string;
  loading?: boolean;
  disabled?: boolean;
  // showLoading?: boolean;
  className?: HTMLAttributes<HTMLButtonElement>["className"];
  onClick?: MouseEventHandler<HTMLButtonElement>;
  others?: HTMLAttributes<HTMLButtonElement>;
}

const Button: FC<ButtonProps> = (props) => {
  let { text, type, className, loading, others, ...rest } = props;
  if (!type) type = "button";

  return (
    <button
      type={type}
      {...rest}
      {...others}
      className={`block bg-blue-2 text-white border border-blue-3 rounded px-3 pt-1 pb-2 mt-7 font-semibold ${className}`}
    >
      {loading ? <Loader /> : text}
    </button>
  );
};

export default Button;
