import {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";

interface IAuth {
  authenticating: boolean;
  token: null | string;
}

interface IAuthContext extends IAuth {
  logout: () => void;
}

export const AuthContext = createContext<undefined | IAuthContext>(undefined);

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [authenticate, setAuthenticate] = useState<IAuth>({
    authenticating: true,
    token: null,
  });

  useEffect(() => {
    const token = localStorage.getItem("admin_token");
    setAuthenticate({
      authenticating: false,
      token,
    });
  }, []);

  const logout = () => {
    localStorage.removeItem("admin_token");
    setAuthenticate({
      authenticating: false,
      token: null,
    });
  };

  return (
    <AuthContext.Provider value={{ ...authenticate, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
