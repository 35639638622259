import { useFormik } from "formik";
import { FC, useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
// import {
//   // Navigate,
//   // useNavigate
// } from "react-router-dom";
import LoginForm, { FormAuthFields } from "../modules/admin/Auth/LoginForm";
import { LoginValidation } from "../modules/admin/Auth/Validations";
import { logInAdmin } from "../utilities/admin";

const Login: FC = () => {
  const navigate = useNavigate();
  const { token } = useAuth();
  const [details, setDetails] = useState({
    loading: false,
    error: false,
  });
  const formik = useFormik<FormAuthFields>({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginValidation,
    onSubmit: async (values) => {
      try {
        setDetails({ ...details, loading: true });
        let token = await logInAdmin(values);
        toast.success("Login Successful");
        console.log({ token });
        localStorage.setItem("admin_token", token);
        // redirect to admin page
        window.location.replace("/admin");
      } catch (error: any) {
        let message = error.message;
        toast.error(message);
      } finally {
        setDetails({ ...details, loading: false });
      }
    },
  });

  useEffect(() => {
    if (token) {
      return navigate("/admin", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="px-4 py-5">
      <LoginForm formik={formik} loading={details.loading} />
    </main>
  );
};

export default Login;
