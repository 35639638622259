import { FormikProps } from "formik";
import { FC } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Select from "../../components/Inputs/Select";
import { SlotsTime } from "../../routes/book";

interface CalendarProps {
  date: Date | null;
  excludeTimes?: Date[];
  handleDateChange: (date: Date) => void;
  // calendar: CalendarOptions;
  // slots: any;
  formik: FormikProps<any>;
}

export interface CalendarOptions {
  showTimeSelect: boolean;
}

export interface Props extends CalendarProps, SlotsTime {}

const Calendar: FC<Props> = ({
  date,
  handleDateChange,
  excludeTimes,
  // slots,
  formik,
  data: slots,
  error,
  loading,
}) => {
  console.log({ error });
  let time = slots.map((slot: string) => {
    // "en-US", { hour12: false }
    // let result = new Date(slot).toLocaleTimeString();

    return {
      // label: result.slice(0, -3),
      // value: new Date(slot).toISOString(),
      label: slot,
      value: slot,
    };
  });

  time = [{ label: `${loading ? "Loading..." : "Time"}`, value: "" }, ...time];

  return (
    <section className="calendar__section">
      <DatePicker
        selected={date}
        onChange={(date: Date) => handleDateChange(date)}
        timeFormat="HH:mm"
        timeIntervals={1}
        timeCaption="time"
        dateFormat="MMMM d, yyyy h:mm aa"
        className="form__input calendar__input"
        open={true}
        excludeTimes={excludeTimes}
        minDate={new Date()}
      />
      <Select
        id="time"
        label="Time"
        name="time"
        onChange={formik.handleChange}
        options={time}
        value={formik.values.time}
        showLabel={false}
        disabled={loading || Boolean(error)}
      />
    </section>
  );
};

export default Calendar;
