import axios from "axios";
import { BaseUrl, paths } from "../constants/baseUrl";
import { getDateFormatOfYYYYMMDD } from "./time";

export const getAvailableSlots = async (date: string) => {
  let formattedDate = getDateFormatOfYYYYMMDD(date);
  let url = `${BaseUrl}${paths.get_available_slots.replace(
    "{yyyy-mm-dd}",
    formattedDate
  )}`;

  url = new URL(url).href;

  try {
    let result = (await axios.get(url)).data;
    return (result.data.slots || []) as string[];
  } catch (error) {
    let message = "Custom Message";
    throw new Error(message);
  }
};

// organization --- id
/*
 */

// c94beba62-1494-448a-9149-564f81461e43
