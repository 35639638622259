import { BaseUrl, paths, SCHEDULE_ID } from "../constants/baseUrl";
import axios, { AxiosInstance } from "axios";
import { isAxiosError } from "./axios";

interface BookingError {
  message: string;
}

export const bookSession = async (details: any) => {
  let url = `${BaseUrl}${paths.book_user}`.replace(
    "{scheduleId}",
    String(SCHEDULE_ID)
  );

  try {
    let result = (await axios.post(url, details)).data as {
      message: string;
    };
    return result.message;
  } catch (error: unknown) {
    let message = "";
    if (isAxiosError<BookingError>(error)) {
      message =
        error.response?.data.message[0] || error.response?.data.message || "";
      // console.warn({ message });
    } else {
      message = "Something went wrong";
    }

    if (!message) message = "Something went wrong";

    throw new Error(message);
  }
};

export interface Booking {
  bookingId: string;
  createdAt: string;
  date: string;
  description: string;
  email: string;
  endTime: string;
  id: number;
  name: string;
  noOfUser: number;
  phoneNumber: string;
  scheduleId: string;
  time: string;
  startTime: string;
  updatedAt: string;
}

interface AllBookings extends FetchSuccess<{ bookings: Booking[] }> {}

interface FetchSuccess<Data> {
  data: Data;
  message: string;
  status: number;
}

export const getBookings = async () => {
  let url = `${BaseUrl}${paths.get_bookings}`;

  try {
    let result = (await axios.get<AllBookings>(url)).data;
    let bookings = result.data.bookings;
    return bookings;
  } catch (error) {
    let message = "Custom Message";
    throw new Error(message);
  }
};

export type Slot = Pick<Booking, "endTime" | "startTime">;

type SlotsSuccess = FetchSuccess<{ slots: Slot[] }>;

export const getSlots = async () => {
  let url = `${BaseUrl}${paths.get_slots}`;

  try {
    let result = (await axios.get<SlotsSuccess>(url)).data;
    return result.data.slots;
  } catch (error) {
    let message = "Custom Message";
    throw new Error(message);
  }
};

export interface FetchData<Data> {
  loading: boolean;
  data: Data[];
  error: string;
}

export interface SearchBooking
  extends Omit<
    Booking,
    "createdAt" | "description" | "id" | "noOfUser" | "updatedAt"
  > {}

export const searchBookings = async (
  search: string,
  startDate: string,
  signal?: AbortSignal
) => {
  try {
    let query = new URL(`${BaseUrl}${paths.search_bookings}`);
    if (search) query.searchParams.append("value", search);
    if (startDate) query.searchParams.append(startDate, startDate);

    let url = query.href;
    console.log({ url });

    let result = (
      await axios.get<{ data: { booking: SearchBooking[] } }>(url, {
        signal,
      })
    ).data.data.booking;

    return result || [];
  } catch (error) {
    throw new Error("Something went wrong");
  }
};

export const getInitialBookings = async (adminInstance: AxiosInstance) => {
  try {
    let url = `${BaseUrl}${paths.get_all_bookings}`;

    let bookings = (
      await adminInstance.get<{ data: { bookings: Booking[] } }>(url)
    ).data.data.bookings;

    return bookings;

    // return bookings.map((booking) => ({
    //   ...booking,
    //   time: new Date(booking.time).toLocaleTimeString(),
    // }));
  } catch (error) {
    throw new Error("Something went wrong");
  }
};
