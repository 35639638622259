import { useRef, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import logo from "./../assets/img/logo512.png";
import { GiHamburgerMenu } from "react-icons/gi";

interface Path {
  path?: string;
  name: string;
  className?: React.HTMLAttributes<HTMLElement>["className"];
  onClick?: () => void;
}

const Navbar = () => {
  const { token, logout } = useAuth();
  const { pathname } = useLocation();
  const linksRef = useRef<HTMLDivElement | null>(null);
  const paths: Path[] = [
    { path: "/book", name: "Book an Appointment", className: "" },
    { path: "/", name: "Home", className: "" },
  ];

  if (token) {
    paths.push({ name: "Logout", onClick: logout, className: "" });
  } else {
    paths.push({ path: "/admin/login", name: "Admin", className: "" });
  }

  const closeMenu = () => {
    let linksCont = linksRef.current;
    if (!linksCont) return;

    linksCont.classList.remove("left-0");
    linksCont.classList.add("-left-full");
  };

  const toggleMenu = (open?: boolean) => {
    let linksCont = linksRef.current;
    if (!linksCont) return;

    if (linksCont.classList.contains("-left-full")) {
      linksCont.classList.remove("-left-full");
      linksCont.classList.add("left-0");
    } else {
      closeMenu();
    }
  };

  // close navbar when route changes
  useEffect(() => {
    closeMenu();
  }, [pathname]);

  return (
    <nav className="pt-6 pb-6 px-6 border-b border-white-4 flex justify-between items-center flex-wrap relative ">
      <Link to="/" className="w-12 inline-block">
        <img src={logo} alt="site logo" />
      </Link>
      <button
        className="md:hidden"
        style={{ fontSize: "2rem" }}
        type="button"
        onClick={() => toggleMenu()}
      >
        <GiHamburgerMenu className="" />
      </button>
      <div
        ref={linksRef}
        className="absolute top-[6.45rem] -left-full w-full z-10 bg-white h-60 transition duration-200 md:static md:h-auto md:w-auto"
      >
        <ul className="flex flex-col space-y-4 md:flex-row md:items-center md:justify-end md:space-x-4 md:space-y-0">
          {paths.map(({ path, name, className, onClick }) => (
            <li key={path} className="text-center">
              {path ? (
                <NavLink
                  to={path}
                  className={({ isActive }) =>
                    `block py-4 hover:text-green-1 ${className || ""} ${
                      isActive ? "text-green-1" : ""
                    }`.trim()
                  }
                >
                  {name}
                </NavLink>
              ) : (
                <button type="button" onClick={onClick}>
                  {name}
                </button>
              )}
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
