import axios from "axios";
import { FormAuthFields } from "../modules/admin/Auth/LoginForm";
import { BaseUrl, paths } from "./../constants/baseUrl";
import { isAxiosError } from "./axios";

interface LoginResult {
  data: {
    token: string;
  };
  message: string;
}

export const logInAdmin = async (data: FormAuthFields) => {
  try {
    let url = `${BaseUrl}${paths.admin_login}`;
    return (await axios.post<LoginResult>(url, data)).data.data.token;
  } catch (error) {
    let message = "";

    if (isAxiosError<{ message: string }>(error)) {
      message = error.response?.data.message || "";
    }

    if (!message) message = "Something went wrong";

    throw new Error(message);
  }
};
