import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import GeneralLayout from "./components/Layouts/General";
import Home from "./routes";
import Book from "./routes/book";
import Admin from "./routes/admin";
import Login from "./routes/login";
import { AuthProvider } from "./contexts/Auth";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Toaster
          toastOptions={{
            duration: 4_000,
            className: "text-[1.3rem] md:text-[1.2rem]",
          }}
        />
        <Routes>
          <Route path="/" element={<GeneralLayout />}>
            <Route index element={<Home />} />
            <Route path="book" element={<Book />} />
            <Route path="admin">
              <Route index element={<Admin />} />
              <Route path="login" element={<Login />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
