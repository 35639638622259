import { ChangeEventHandler, FC } from "react";
import { InputProps } from "./Index";

export interface SelectProps extends Omit<InputProps, "onChange"> {
  options: { value: string; label: string }[];
  onChange: ChangeEventHandler<HTMLSelectElement>;
  disabled?: boolean;
}

const Select: FC<SelectProps> = (props) => {
  return (
    <div
      className={`form__control form__control--flex ${
        props.containerClassName || ""
      }`.trim()}
    >
      <label
        htmlFor={props.id}
        className={`form__label ${props.showLabel ? "" : "hidden"} ${
          props.labelClassName || ""
        }`.trim()}
      >
        {props.label}
      </label>
      <select
        name={props.name}
        id={props.id}
        onChange={props.onChange}
        value={props.value}
        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md disabled:cursor-not-allowed disabled:bg-[#666] disabled:text-white"
        disabled={props.disabled}
      >
        {props.options.map((option) => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;

/*
<div>
  <label for="location" class="block text-sm font-medium text-gray-700">Location</label>
  <select id="location" name="location" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
    <option>United States</option>
    <option selected>Canada</option>
    <option>Mexico</option>
  </select>
</div>
*/
